<template>
	<div class="pump-map-home">
		<!-- <div class="head fs-5 mb-2">
      <i class="bi bi-map pr-2" style="color: #0d6efd"></i>
      二次供水泵房管理
    </div> -->
		<sidebar />
		<div class="map-container">
			<header-top />
			<div class="page-title">
				<span>二次供水泵房管理 泵房导航</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>泵房导航</li>
					</ul>
				</div>
			</div>
			<div class="action-container">
				<div class="d-flex search-wrap">
					<el-input placeholder="搜索泵房" size="medium"></el-input>
					<el-button type="primary" size="medium" class="ml-2">所有</el-button>
				</div>

				<div class="pump-list-wrap">
					<div class="fw-bold text-white text-center py-2 title">
						当前区域泵房
					</div>
					<div class="pump-list">
						<el-scrollbar
							class="main-scrollbar"
							style="height: 100%"
							ref="unpayBillWrap"
						>
							<div
								class="pump-item"
								v-for="(item, index) in pumpList"
								:key="index"
							>
								<div>
									<span class="label">泵房名</span>
									<span class="content">{{ item.name }}</span>
								</div>
								<div>
									<span class="label">网关IP</span>
									<span class="content">{{ item.IP }}</span>
								</div>
								<div>
									<span class="label">最近在线</span>
									<span class="content">{{ item.Time }}</span>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>

			<!-- <el-amap vid="amapDemo" style="height: 100%"> </el-amap> -->

			<baidu-map
				style="height: 800px"
				:center="center"
				:zoom="zoom"
				:scroll-wheel-zoom="true"
				@ready="mapReady"
			>
				<bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
				<bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>

				<bm-overlay
					pane="markerMouseTarget"
					:class="{ sample: true, active }"
					@mouseover.native="active = true"
					@mouseleave.native="active = false"
				>
					<!-- <Com title="111" /> -->
				</bm-overlay>
			</baidu-map>
		</div>

		<!-- <div id="demo123">

  </div> -->
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
import { mapState } from 'vuex'

// import { getToken } from "@/session.js";
// import Com from "./Com";
// import Vue from 'vue'

export default {
	name: 'Home',
	components: {
		// Com,
		sidebar,
		headerTop,
	},
	data() {
		console.log('this', this)
		return {
			center: { lng: 0, lat: 0 },
			zoom: 3,

			active: false,

			pumpList: [
				{
					name: '香开长龙：泵房',
					IP: '1.0.116.0',
					Time: '2021-04-14 14:27:49',
				},
				{
					name: '东海湾御花园：#1',
					IP: '1.0.84.0',
					Time: '2020-11-12 19:16:43',
				},
			],

			points: [],
		}
	},
	computed: {
		...mapState(['IndiSocialId', 'IndiOwnerId', 'IndiTruename']),
	},

	mounted() {
		// const state = Vue.observable({ title: '123456' })
		// let C = Vue.extend(Com)
		// const vm = new C({
		//   propsData: {
		//     title: state.title
		//   }
		// }).$mount('#demo123')
		// console.log('自定义组件', vm)
		// setTimeout(() => {
		//   // state.title = +new Date()
		//   vm.$destroy()
		//   console.log('销毁实例')
		// },5000)
	},

	methods: {
		mapReady(e) {
			const { BMap, map } = e

			console.log(BMap, map)
			this.center.lng = 116.404
			this.center.lat = 39.915
			this.zoom = 15

			console.log('百度地图', map)

			setTimeout(() => {
				map.addEventListener('click', (e) => {
					console.log('地图被点击', e)
				})

				map.addEventListener('dragend', function() {
					// console.log('dragend  this', this)

					var center = map.getCenter()
					console.log('地图中心点变更为：' + center.lng + ', ' + center.lat)
				})

				map.addEventListener('zoomend', function() {
					console.log('地图缩放至：' + this.getZoom() + '级')
				})

				var point = new BMap.Point(116.404, 39)
				map.centerAndZoom(point, 15)

				// 覆盖物
				var marker = new BMap.Marker(point) // 创建标注

				marker.setIcon(
					new BMap.Icon(
						'https://devapi.beidiancloud.cn/image/2021/05/25/20210525163408_1875d80d7d7241c5a87b6ebee32b934c.jpg',
						new BMap.Size(23, 25),
						{
							// 指定定位位置。
							// 当标注显示在地图上时，其所指向的地理位置距离图标左上
							// 角各偏移10像素和25像素。您可以看到在本例中该位置即是
							// 图标中央下端的尖角位置。
							anchor: new BMap.Size(10, 25),
							// 设置图片偏移。
							// 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
							// 需要指定大图的偏移位置，此做法与css sprites技术类似。
							imageOffset: new BMap.Size(0, 0 - 1 * 25), // 设置图片偏移
						}
					)
				)

				map.addOverlay(marker) // 将标注添加到地图中

				marker.addEventListener('click', function() {
					alert('您点击了标注')
				})

				// var polyline = new BMap.Polyline([
				//     new BMap.Point(116.399, 39.910),
				//     new BMap.Point(116.405, 39.920)
				//     ],
				//     {strokeColor:"blue", strokeWeight:6, strokeOpacity:0.5}
				//     );
				// map.addOverlay(polyline);

				// var label = new BMap.Label('label,label')
				// map.addOverlay(label);
			}, 2000)
		},
	},
}
</script>

<style lang="scss">
.el-scrollbar__wrap {
	overflow-x: hidden;
}

.sample {
	width: 120px;
	height: 40px;
	line-height: 40px;
	background: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	box-shadow: 0 0 5px #000;
	color: #fff;
	text-align: center;
	padding: 10px;
	position: absolute;
}
.sample.active {
	background: rgba(0, 0, 0, 0.75);
	color: #fff;
}
</style>

<style lang="scss">
.pump-map-home {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.map {
    width: 400px;
		height: 300px;
	}
	.map-container {
    padding:0 20px;
		position: relative;
		flex: 8;
    height: 100%;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.action-container {
			width: 250px;
			position: absolute;
			top: 120px;
			right: 45px;
			bottom: 20px;
			// background: #fff;
			z-index: 99;

			// padding: 20px;

			display: flex;
			flex-direction: column;

			.search-wrap {
				flex: 0 0 auto;
			}

			.pump-list-wrap {
				border-radius: 4px;
				flex: 1 1 auto;
				margin-top: 15px;
				background: rgba($color: #000000, $alpha: 0.2);

				overflow: hidden;
				// display: flex;
				// flex-direction: column;

				// .title {
				//   flex: 0 0 auto;
				// }

				.pump-list {
					// flex: 1 1 auto;
					height: calc(100% - 40px);

					.pump-item {
						cursor: pointer;
						margin: 8px 10px;
						background: rgba($color: #fff, $alpha: 0.3);
						font-size: 14px;
						padding: 8px;

						.label {
							display: inline-block;
							width: 60px;
							text-align-last: justify;
							padding-right: 4px;
							color: #666;
						}

						.content {
							color: #333;
						}
					}
				}
			}
		}
	}
}
</style>
